<template>
  <div class="check-list">
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">AI检测详情</div>
      <div class="header-left">
        <userDeviceListVue @changeForm="changeForm" v-if="deviceUsers" :list="deviceUsers" :form="form" />
      </div>
    </div>
    <div class="h35">&nbsp;</div>
    <template v-if="aiCheckList && aiCheckList.length">
      <div v-for="(item, index) in aiCheckList" :key="index" class="check-cells">
        <div class="check-info">
          <div class="check-info-item"><div class="tip">检测日期：{{ item.checkDatetime | formatDate }}</div></div>
          <div class="check-info-item">
            <span>双眼： {{item.eyeTwoVal}}</span>
            <img v-if="item.eyeTwoComp < 0" src="@/accesst/check/down.png" alt="" srcset="" />
            <img v-if="item.eyeTwoComp > 0" src="@/accesst/check/up.png" alt="" srcset="" />
            <img v-if="item.eyeTwoComp == 0 || item.eyeTwoComp == null" src="@/accesst/check/hr.png" alt="" srcset="" />
            {{ item.eyeTwoComp == 0 || item.eyeTwoComp == null ? '与平均值持平': (item.eyeTwoComp > 0 ? '高于平均值'+ item.eyeTwoComp + '%' : '低于平均值'+ item.eyeTwoComp*-1 + '%') }}
          </div>
          <div class="check-info-line"></div>
          <div class="check-info-item">
            <span>左眼： {{item.eyeLeftVal}}</span>
            <img v-if="item.eyeLeftComp < 0" src="@/accesst/check/down.png" alt="" srcset="" />
            <img v-if="item.eyeLeftComp > 0" src="@/accesst/check/up.png" alt="" srcset="" />
            <img v-if="item.eyeLeftComp == 0 || item.eyeLeftComp == null" src="@/accesst/check/hr.png" alt="" srcset="" />
            {{ item.eyeLeftComp == 0 || item.eyeLeftComp == null ? '与平均值持平': (item.eyeLeftComp > 0 ? '高于平均值'+ item.eyeLeftComp + '%' : '低于平均值'+ item.eyeLeftComp*-1 + '%') }}
          </div>
          <div class="check-info-line"></div>
          <div class="check-info-item">
            <span>右眼： {{item.eyeRightVal}}</span>
            <img v-if="item.eyeRightComp < 0" src="@/accesst/check/down.png" alt="" srcset="" />
            <img v-if="item.eyeRightComp > 0" src="@/accesst/check/up.png" alt="" srcset="" />
            <img v-if="item.eyeRightComp == 0 || item.eyeRightComp == null" src="@/accesst/check/hr.png" alt="" srcset="" />
            {{ item.eyeRightComp == 0 || item.eyeRightComp == null ? '与平均值持平': (item.eyeRightComp > 0 ? '高于平均值'+ item.eyeRightComp + '%' : '低于平均值'+ item.eyeRightComp*-1 + '%') }}
          </div>
        </div>
      </div>
    </template>
    <van-empty v-else description="暂无数据" />
    <div class="h35">&nbsp;</div>
  </div>
</template>

<script>
import { Icon, Empty, Radio, DatetimePicker, Popup, Picker } from 'vant';
import userDeviceListVue from '@/view/components/userDeviceList.vue';
import { isArray, groupBy, forEach, reverse } from 'lodash';
import { getUserInfo, bindDevicePage, deviceUserPage, getUserAiCheck } from '@/api/h5';
import moment from 'moment'

export default {
  components: {[Icon.name]: Icon, userDeviceListVue, [Empty.name]: Empty},
  data() {
    return {
      form: {},
      deviceUsers: [],
      aiCheckList: []
    }
  },
  filters: {
    formatDate(v) {
      console.log(v)
      return moment(v).format('yyyy年MM月DD日 HH:mm:ss')
    }
  },
  created() {
    this.form = {...this.$route.query };
    deviceUserPage({pageSize: 100}).then(dusers => {
      console.log(dusers, '设备<--->使用者列表')
      if(dusers && dusers.list && dusers.list.length) {
        const list = dusers.list;
        const deviceUsers = [];        
        
        forEach(groupBy(list, 'deviceId'), (v, k)=> {
          const items = [];
          forEach(v, vv => {
            items.push({code: vv.deviceUserId, name: vv.userName, _v: `${k}_${vv.deviceUserId}`});
          })
          deviceUsers.push({name: v[0].deviceName, code: k, items, _v: k });
        })        
        this.deviceUsers = deviceUsers;
        this.initData();
      }
    })
  },
  methods: {
    changeForm(form) {
      console.log('changeForm', form);
      this.form = {
        ...this.form,
        ...form
      }
      this.initData();
    },
    initData() {
      getUserAiCheck({deviceId: this.form.deviceId, devUserNo: this.form.deviceUserId}).then(res => {
        console.log(res, 'AI 检测数据')
        if(res && res.aiCheckList && res.aiCheckList.length) {
          this.aiCheckList.length = 0;
          this.aiCheckList = reverse(res.aiCheckList);
        } else {
          this.aiCheckList.length = 0;
          this.aiCheckList = [];
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.h35 {
  height: 35px;
}
.check-list {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  .check-cells {
    margin: .23rem auto 0;
    width: 6.92rem;
    // height: 3.81rem;
    background: #F7F8FE;
    border-radius: .1rem;
    .check-info {
      .tip {
        font-size: .24rem;
        color: #666
      }
      padding: 0 .3rem;
      .check-info-item {
        height: .88rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: .27rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #000000;
        span {
          width: 1.85rem;
        }
        img {
          width: .31rem;
          margin: 0 .8rem 0 .2rem;
        }
      }
      .check-info-line {
        height: .02rem;
        background: #EEEEEE;
      }      
    }
    .check-tip-info {
      width: 100%;
      height: 1.31rem;
      background: url('../accesst/check/bg.png') no-repeat;
      background-size: 100% 1.31rem;
      border-radius: 0 0 .12rem .12rem;
      .check-tip-title {
        padding: .2rem 0 0 .27rem;
        font-size: .31rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #2B67D9;
      }
      .check-tip-des {
        padding: .12rem 0 0 .27rem;
        font-size: .23rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #B9C2D5;
      }
    }
  }
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      width: auto;
    }
  }
}
</style>